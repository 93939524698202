enum MasterData {
	Countries_Read = 150,
	Countries_Update = 151,
	Sites_Read = 200,
	Sites_Update = 201,
	RobotTypes_Read = 250,
	RobotTypes_Update = 251,
	Departments_Read = 300,
	Departments_Update = 301,
	Technologies_Read = 350,
	Technologies_Update = 351,
	ErrorReason_Read = 360,
	ErrorReason_Update = 361,
	AgentVersion_Download = 1005,
}

enum Infrastructure {
	Machines_Read = 400,
	Machines_Update = 401,
	MachineTypes_Read = 450,
	MachineTypes_Update = 451,
	OperationSystems_Read = 500,
	OperationSystems_Update = 501,
}

enum Process {
	Dashboard_Read = 550,
	Dashboard_Runner = 551,
	Schedule_Read = 600,
	Schedule_Update = 601,
	Processes_Read = 650,
	Processes_Update = 651,
	Steps_Read = 700,
	Steps_Update = 701,
	AgentLog_Read = 710,
}

enum Security {
	GlobalAdministrator = 2,
	UserManagement = 750,
	GroupManagement = 800,
	RoleManagement = 850,
	Settings = 900,
	APIClients = 950,
	SignInAudit = 1008
}

enum Notifications {
	Notification_Read = 1000,
	Notification_Create = 1001,
	Notification_Restore = 1006,
}

export const RolesDictionary = {
	MasterData,
	Infrastructure,
	Process,
	Security,
	Notifications,
};
