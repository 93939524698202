import { useTranslation } from "react-i18next";
import { SchedulePostRequestViewModel } from "../../../model/schedule.model";
import { ScheduleService as Service } from "../../../services/schedule.service"
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FormikErrors, FormikTouched } from "formik";
import { AvailableMachinesRequestViewModel, MachineBaseViewModel } from "app/machine/model/machine.model";

interface Props {
	startDate: string;
	endDate: string;
	recurringType: number;
	processId: number;
	values: SchedulePostRequestViewModel;
	errors: FormikErrors<SchedulePostRequestViewModel>;
	touched: FormikTouched<SchedulePostRequestViewModel>;
	setTouched: (touched: FormikTouched<SchedulePostRequestViewModel>, shouldValidate?: boolean | undefined) => Promise<FormikErrors<SchedulePostRequestViewModel>> | Promise<void>;
	step: any;
	handleChange: (event: any) => void;
	handleBlur: (event: any) => void;
	setFieldsValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void> | Promise<FormikErrors<SchedulePostRequestViewModel>>;
}

export function Step4(props: Props) {

	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [availableMachines, setAvailableMachines] = useState<any>([]);

	const fetchAvailableMachines = async (requestBody: AvailableMachinesRequestViewModel) => {
		setIsLoading(true);
		try {
			const response = await Service.getAvailableMachinesList(requestBody);
			if (response && !response.hasErrors) {
				setAvailableMachines(response.data);
			}
		} catch (error) {
			toast.error(`${t("crud.read.errorMessage")}`);
		} finally {
			setIsLoading(false);
		}
	}

	useEffect(() => {
		if (props.startDate !== "") {
			const endDate = props.recurringType !== 0 ? props.endDate : "";

			const requestBody: AvailableMachinesRequestViewModel = {
				startDate: props.startDate,
				endDate: endDate,
				processId: props.processId
			}

			fetchAvailableMachines(requestBody);
		}

		return (() => {
			setAvailableMachines([]);
			props.setFieldsValue("machineIds", null);
			props.setTouched({ machineIds: false });
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.step === 4]);

	return (
		<div className="row w-100">
			<div className="col-12 mb-10">
				<label className="d-flex align-items-center fs-5 fw-semibold mb-4">
					<span className="required">{t("scheduleModal.fields.availableMachines")}</span>
					<i
						className="fas fa-exclamation-circle ms-2 fs-7"
						data-bs-toggle="tooltip"
						title="Specify your unique app name"
					></i>
				</label>

				<div style={{ overflowY: 'scroll', height: '20rem' }} >
					{
						availableMachines.map((availableMachine: MachineBaseViewModel, index: number) => (
							<div className="mb-3" key={index}>
								<div className="d-flex align-items-center">
									<label className="form-check form-check-custom form-check-solid me-3">
										<input
											className="form-check-input h-20px w-20px"
											type="checkbox"
											name="machineIds"
											value={availableMachine.id}
											id={'' + availableMachine.id}
											onChange={props.handleChange}
											onBlur={props.handleBlur}
										/>
									</label>
									<label
										className="form-label cursor-pointer mb-0"
										htmlFor={'' + availableMachine.id}
									>
										{availableMachine.name}
									</label>
								</div>
							</div>
						))
					}
				</div>

				{isLoading && (
					<div className="text-center mb-8">
						<span className="indicator-progress" style={{ display: 'block' }}>
							<span className="spinner-border spinner-border-md align-middle my-10 text-primary"></span>
						</span>
					</div>
				)}

				{!isLoading && availableMachines.length === 0 &&
					<div className="mt-3 text-muted text-center">
						{t("scheduleModal.fields.noMachine")}
					</div>
				}

				{props.touched.machineIds && props.errors.machineIds && (
					<div className="mt-3 text-danger fw-bold">
						{props.errors.machineIds}
					</div>
				)}
			</div>
		</div >
	)
}
