import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Modal } from 'react-bootstrap'
import { Autocomplete, TextField, Tooltip } from '@mui/material';
import { RobotTypeService } from 'app/robot-type/services/robotType.service';
import { RobotTypeModel } from 'app/robot-type/model/robotType.model';
import closeModalIcon from 'assets/images/icons/close.svg'
import copyIcon from 'assets/images/icons/copy.svg';
import alphaBg from 'assets/images/patterns/alpha.png';
import ITSVG from 'common/helpers/ITSVG';
import SaveButton from 'common/partials/SaveButton';
import { StatusFilter } from 'common/types/status-filter.model';
import { ProcessStepsModel } from '../model/process-steps.model';
import { ProcessStepsService as Service } from '../services/process-steps.service';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';
import ExecutionTypeEnum from 'common/enums/ExecutionTypeEnum';
import HttpMethodEnum from 'common/enums/HttpMethodEnum';
import DataTable, { TableColumn } from 'react-data-table-component';
import { StepHttpHeader } from '../model/step-http-header.model';
import ActionButton from 'common/partials/ActionButton';
import { itLabTableStyle } from 'common/helpers/table/table-style';
import { Header } from 'common/layout/components/header/Header';
import { Key } from '@mui/icons-material';


interface Model extends ProcessStepsModel {
	key?: string,
	value?: string
}

interface Props {
	id: number;
	open: boolean;
	handleOpen: (id: number) => void;
	handleClose: () => void;
	handleFetchModalData: () => void;
}

export function CreateEditProcessSteps(props: Props) {
	i18next.addResourceBundle('us', 'translation', en);
	i18next.addResourceBundle('br', 'translation', pt);
	i18next.addResourceBundle('es', 'translation', es);

	const { t } = useTranslation();
	const { id } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [tooltipText, setTooltipText] = useState<string>(t("generalMessages.copy"));

	const formSchema = Yup.object().shape({
		name: Yup.string()
			.required(t('crud.validators.requiredField'))
			.max(70, t('crud.validators.maxLength_70')),
		description: Yup.string()
			.required(t('crud.validators.requiredField'))
			.max(1024, t('crud.validators.maxLength_1024')),
		executable: Yup.string()
			.required(t('crud.validators.requiredField'))
			.max(512, t('crud.validators.maxLength_512')),
		filePath: Yup.string()
			.when('executionType', {
				is: ExecutionTypeEnum.Executable,
				then: Yup.string()
					.required(t('crud.validators.requiredField'))
					.max(512, t('crud.validators.maxLength_512')),
				otherwise: Yup.string().nullable(),
			}),
		version: Yup.number()
			.required(t('crud.validators.requiredField'))
			.max(5, t('crud.validators.maxLength_5')),
		averageTimeInMinutes: Yup.string()
			.required(t('crud.validators.requiredField'))
			.nullable(),
		robotId: Yup.string()
			.required(t('crud.validators.requiredField')),
		executionType: Yup.mixed()
			.required(t('crud.validators.requiredField')),
		key: Yup.string()
			.when('executionType', {
				is: ExecutionTypeEnum.HttpRequest,
				then: Yup.string()
		 				 .matches(/^[!#$%&'*+\-.^_`|~0-9A-Za-z]+$/, t('crud.validators.invalidCharacters')),
				otherwise: Yup.string().nullable()
			})
			.test('uniqueHeader', t('crud.validators.duplicatedKey'),function (value) : boolean {
				const newKey = {key : value } as StepHttpHeader;
				return uniqueStepHeaderValidator([...(formik.values.stepHttpHeaders as StepHttpHeader[]), newKey], value as string);
			}),
		value: Yup.string(),
		stepHttpHeaders: Yup.array().of(
		 	Yup.object()
			   .shape({
		 		   key: Yup.string()
		 			   .required(t('crud.validators.requiredField'))
		 			   .matches(/^[!#$%&'*+\-.^_`|~0-9A-Za-z]+$/, t('crud.validators.invalidCharacters'))
			   		   .test('uniqueHeader', t('crud.validators.duplicatedKey'),(value) : boolean => uniqueStepHeaderValidator(formik.values.stepHttpHeaders as StepHttpHeader[], value as string)),
		 		   value: Yup.string()
         			   .required(t('crud.validators.requiredField')),
               }))
			   .nullable(),
				//.test('uniqueHeader', t('crud.validators.duplicatedKey'),(value) : boolean => uniqueStepHeaderValidator(formik.values.stepHttpHeaders as StepHttpHeader[], value as string)),
		httpMethod: Yup.mixed()
			.when('executionType', {
				is: ExecutionTypeEnum.HttpRequest,
				then: Yup.mixed()
					.required(t('crud.validators.requiredField')),
				otherwise: Yup.mixed().nullable(),
			}),
	});

function uniqueStepHeaderValidator(stepHttpHeaders: StepHttpHeader[], key: string): boolean {
	if(!stepHttpHeaders || !Array.isArray(stepHttpHeaders) || !key) return true;
	const map = new Map<string, number>();
	for(let i = 0; i < stepHttpHeaders.length; i++) {
		const currentKey = stepHttpHeaders[i].key;
		const count = map.get(currentKey) || 0;
		if (currentKey == key && count >= 1)
			return false;
		map.set(currentKey, count + 1);
	}
	return true;
}

const initialData: Model = {
	id: parseInt(id ?? '0') ?? null,
	name: '',
	description: '',
	version: 0,
	color: '#009ef7',
	processId: 0,
	executable: '',
	arguments: '',
	key: '',
	value: '',
	isActive: true,
	filePath: '',
	robotId: undefined,
	averageTimeInMinutes: undefined,
	allowParallelExecution: false,
	httpMethod: undefined,
	executionType: undefined,
	stepHttpHeaders: []
};

	const [data, setData] = useState<Model>(initialData);
	const [robotTypes, setRobotTypes] = useState<RobotTypeModel[]>([]);
	const [executionType, setExecutionType] = useState<ExecutionTypeEnum | null | undefined>(null);
	const [file, setFile] = useState<any>(null);
	const [uploadIsAllowed, setUploadIsAllowed] = useState(false);
	const [isColorPickerVisible, setIsColorPickerVisible] = useState(true);
	const averageTimeInMinutes = [10, 20, 30, 60, 90, 120]
	const [averageTimeInMinutesDefaultValue, setAverageTimeInMinutesDefaultValue] = useState<number | undefined | null>(null);
	const [robotTypeDefaultValue, setRobotTypeDefaultValue] = useState<RobotTypeModel | undefined | null>(null);
	const [httpMethodDefaultValue, setHttpMethodDefaultValue] = useState<HttpMethodEnum | null | undefined>(null);

	const addKeyValuePair = async (formik : any) => {
		try {
			 const values = formik.values;
			 const headers = values.stepHttpHeaders || [];

			 if(!values.key || !values.value) return
			 const h = {key: values.key, value: values.value} as StepHttpHeader;
			 
			 formik.setFieldValue('stepHttpHeaders', [h, ...headers]);
			 formik.setFieldValue('key', '');
			 formik.setFieldValue('value', '');
		} catch (error) {
		  toast.error(`${t('crud.create.invalidKey')}`);
		  return;
		}
	  };

	const deleteStepHttpHeader = (step: StepHttpHeader) => {
		const headers = formik.values.stepHttpHeaders || [];
        const updatedHeaders = headers.filter((s: StepHttpHeader) => s.key !== step.key);

        formik.setFieldValue("stepHttpHeaders", updatedHeaders);
	};

	const handleCancel = () => {
		formik.resetForm();
		props.handleClose();
	};

 function httpStepHeaderErrorElement(index: number) {
	const errors = formik.errors.stepHttpHeaders as unknown as Array<{key: string}>;
	if(!formik.touched.stepHttpHeaders || !errors?.[index]) return;
	return <div className="mt-3 text-danger fw-bold">{(errors)[index]?.key}</div>
 }
	const columns: TableColumn<StepHttpHeader>[] = [
		{
			name: `${t('form.table.key')}`,
			cell:(row: StepHttpHeader, index: number) => ( 
				<div>
						<input 
							type="text"
							name={`stepHttpHeaders[${index}].key`}
							style={{ width: '100%', textAlign: 'left' }}
							className="form-control form-control-lg form-control-solid"
							value={row.key}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
						{httpStepHeaderErrorElement(index)}
						</div>
		)
		},
		{
			name: `${t('form.table.value')}`,
			cell:(row: StepHttpHeader, index: number) => <input 
							type="text"
							name={`stepHttpHeaders[${index}].value`}
							style={{width:  '90%', textAlign: 'left'}}
							className="form-control form-control-lg form-control-solid"
							value={row.value}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
			/>,
		},
		{
			name: `${t('table.actions')}`,
			cell: (row, index) => (
				<div className="d-grid d-flex gap-2">
					<ActionButton type='delete' onClick={() => deleteStepHttpHeader(row)} />
				</div>
			),
			center: true,
			width: '100px'
		},
	];

	const onFileChange = (event: any) => {
		event.preventDefault();
		setFile({ selectedFile: event.target.files[0] });
	};

	const formik = useFormik<Model>({
		enableReinitialize: true,
		initialValues: data,
		validationSchema: formSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true);
				setIsLoading(true);

				const body: Model = {
					id: data.id,
					name: values.name,
					description: values.description,
					version: values.version,
					color: values.color,
					executable: values.executable,
					robotId: values.robotId,
					arguments: values.arguments,
					averageTimeInMinutes: values.averageTimeInMinutes,
					processId: parseInt(id ?? '0'),
					isActive: values.isActive,
					allowParallelExecution: values.allowParallelExecution,
					httpMethod: values.httpMethod,
					stepHttpHeaders: values.stepHttpHeaders
				};
				if (file) {
					body.formFile = file.selectedFile
				}
				if (values.filePath) {
					body.filePath = values.filePath
				}
				if (!isColorPickerVisible) {
					body.color = '';
				}
				if (values.color === null) {
					body.color = '#1e1e2d';
				}
				if (executionType === ExecutionTypeEnum.Executable) {
					body.httpMethod = HttpMethodEnum.None;
					body.stepHttpHeaders = [];
				} else {
					body.filePath = '';
				}

			let result;
			if (props.id) {
				result = await Service.putData(body);
			} else {
				result = await Service.postData(body);
			}

			if (result && !result?.hasErrors) {
				toast.success(`${t(props.id ? 'crud.update.successMessage' : 'crud.create.successMessage')}`);

				props.handleClose();
				props.handleFetchModalData();
			} else {
				toast.error(`${t(`error.${result?.errorCode}`)}`);
			}
		} catch (error) {
			toast.error(`${t('crud.update.errorMessage')}`);
		} finally {
			setIsLoading(false);
			setSubmitting(false);
		}
		},
	});

	const fetchData = async () => {
		const allowUpload = await Service.allowUpload();
		if (allowUpload && !allowUpload.hasErrors) {
			setUploadIsAllowed(allowUpload?.data!);
		}
		if (props.id > 0) {
			const response = await Service.getDataById(props.id);
			if (response && !response.hasErrors) {
				const executionType = response.data.httpMethod == HttpMethodEnum.None ? ExecutionTypeEnum.Executable : ExecutionTypeEnum.HttpRequest;
				setData({ ...response.data, executionType });
				setIsColorPickerVisible(response.data.color ? true : false);
				setAverageTimeInMinutesDefaultValue(averageTimeInMinutes.find(value => value === response.data.averageTimeInMinutes))
				setHttpMethodDefaultValue(response.data.httpMethod ?? null);
				setExecutionType(executionType);
			} else {
				toast.error(`${t("crud.read.errorMessage")}`);
			}
		} else {
			setData(initialData);
			formik.resetForm();
			setAverageTimeInMinutesDefaultValue(null);
			setRobotTypeDefaultValue(null);
		}
	}

	const fetchRobotType = async () => {
		const response = await RobotTypeService.getDataList(StatusFilter.Active);
		if (response && !response.hasErrors) {
			setRobotTypes(response.data);
			if (data.robotId && data.robotId > 0) {
				setRobotTypeDefaultValue(response?.data.find(value => value.id === data.robotId));
			}
		}
	};

	useEffect(() => {
		if (props.open) {
			fetchData();
			setRobotTypes([]);
			setExecutionType(null);
			setHttpMethodDefaultValue(undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.open]);

	useEffect(() => {
		if ((data && data !== initialData) || (data && !props.id)) {
			fetchRobotType();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])

	return (
		<Modal
			show={props.open}
			onHide={handleCancel}
			dialogClassName='modal-dialog-centered mw-900px h-auto'
		>
			<div className='modal-content'>
				<div className='modal-header'>
					<h3>{props.id ? t('updateDataButton') : t('addDataButton')}</h3>
					<div className='btn btn-sm btn-icon btn-active-color-primary' onClick={props.handleClose}>
						<ITSVG path={closeModalIcon} className='svg-icon-1' />
					</div>
				</div>
				<form onSubmit={formik.handleSubmit}>
					<div className="modal-body scroll-y overflow-auto mx-5 my-5">
						<div className="row">
							<div className="col-md-6 mb-10">
								<label className="required form-label">{t('form.label.name')}</label>
								<input
									type="text"
									name="name"
									className="form-control form-control-lg form-control-solid"
									placeholder={t('form.placeholder.name')}
									value={formik.values.name ?? ''}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
								{formik.touched.name && formik.errors.name && (
									<div className="mt-3 text-danger fw-bold">{formik.errors.name}</div>
								)}
							</div>
							<div className="col-md-6 mb-10">
								<label className="required form-label">{t('form.label.description')}</label>
								<input
									type="text"
									name="description"
									className="form-control form-control-lg form-control-solid"
									placeholder={t('form.placeholder.description')}
									value={formik.values.description ?? ''}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
								{formik.touched.description && formik.errors.description && (
									<div className="mt-3 text-danger fw-bold">{formik.errors.description}</div>
								)}
							</div>
							<div className="col-md-6 mb-10">
								<label className="required form-label">{t('form.label.version')}</label>
								<input
									type="number"
									name="version"
									className="form-control form-control-lg form-control-solid"
									placeholder={t('form.placeholder.version')}
									value={formik.values.version}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
								{formik.touched.version && formik.errors.version && (
									<div className="mt-3 text-danger fw-bold">{formik.errors.version}</div>
								)}
							</div>
							<div className="col-md-6 mb-10">
								<label className="form-label">{t('form.label.color')}</label>
								<div className="d-flex align-items-center justify-content-between">
									<div>
										{
											isColorPickerVisible ? (
												<div>
													<input
														className="form-control form-control-lg form-control-solid form-control-color"
														style={{ width: 100 }}
														type="color"
														name="color"
														defaultValue={!formik.values.color ? '#009ef7' : ''}
														value={formik.values.color}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														disabled={!isColorPickerVisible}
													/>
												</div>
											) : (
												<div>
													<img className="rounded-2 cursor-pointer" width={100} height={45.88} src={alphaBg} alt="No color" />
												</div>
											)
										}
									</div>

									<div className="d-flex align-items-center">
										{isColorPickerVisible ? (
											<span className="form-label me-3">{t('form.label.color')}</span>
										) : (
											<span className="form-label me-3">{t('form.label.noColor')}</span>
										)}
										<div className="form-check form-check-solid form-switch">
											<input
												className="form-check-input w-45px h-30px"
												type="checkbox"
												checked={isColorPickerVisible}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIsColorPickerVisible(e.target.checked)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 mb-10">
								<label className="required form-label">{t('form.label.averageTime')}</label>
								<Autocomplete
									id="averageTimeInMinutes"
									options={averageTimeInMinutes}
									value={averageTimeInMinutesDefaultValue ?? null}
									getOptionLabel={option => option.toString() ? option.toString() : ""}
									onChange={(_, value) => {
										formik.setFieldValue("averageTimeInMinutes", value);
										setAverageTimeInMinutesDefaultValue(value);
									}}
									onInputChange={(_, value) => {
										if (!value) {
											setAverageTimeInMinutesDefaultValue(null);
											formik.setFieldValue("averageTimeInMinutes", undefined);
										}
									}}
									onBlur={() => formik.setFieldTouched("averageTimeInMinutes")}
									renderInput={(params) =>
										<TextField
											{...params}
											placeholder={`${t('generalMessages.select')}`}
										/>
									}
								/>
								{formik.touched.averageTimeInMinutes && formik.errors.averageTimeInMinutes && (
									<div className="mt-3 text-danger fw-bold">{formik.errors.averageTimeInMinutes}</div>
								)}
							</div>
							<div className="col-md-4 mb-10">
								<label className="required form-label">{t('form.label.robotType')}</label>
								<Autocomplete
									id="robotId"
									disablePortal
									options={robotTypes}
									value={robotTypeDefaultValue ?? null}
									getOptionLabel={option => option.name ? option.name : ""}
									onChange={(_, value) => {
										setRobotTypeDefaultValue(value)
										formik.setFieldValue("robotId", value?.id)
									}}
									onInputChange={(_, value) => {
										if (!value) {
											setRobotTypeDefaultValue(null)
											formik.setFieldValue("robotId", undefined)
										}
									}}
									onBlur={() => formik.setFieldTouched("robotId")}
									renderInput={(params) =>
										<TextField
											{...params}
											placeholder={`${t('generalMessages.select')}`}
										/>
									}
								/>
								{formik.touched.robotId && formik.errors.robotId && (
									<div className="mt-3 text-danger fw-bold">{formik.errors.robotId}</div>
								)}
							</div>
							<div className="col-md-4 mb-10">
								<label className="required form-label">{t('form.label.executionType')}</label>
								<Autocomplete
									id="executionType"
									disablePortal
									options={Object.keys(ExecutionTypeEnum)}
									value={executionType ?? null}
									getOptionLabel={option => option ? t(`form.executionType.${option.toString().toLowerCase()}`) : ""}
									onChange={(_, value) => {
										const v = value as ExecutionTypeEnum;
										setExecutionType(v);
										formik.setFieldValue("executionType", v);
									}}
									onInputChange={(_, value) => {
										if (!value) {
											setExecutionType(null)
											formik.setFieldValue("executi onType", null)
										}
									}}
									onBlur={() => formik.setFieldTouched("executionType")}
									renderInput={(params) =>
										<TextField
											{...params}
											placeholder={`${t('generalMessages.select')}`}
										/>
									}
								/>
								{formik.touched.executionType && formik.errors.executionType && (
									<div className="mt-3 text-danger fw-bold">{formik.errors.executionType}</div>
								)}
							</div>
							{executionType && (
								<>
									<div className="col-md-6 mb-10">
										<label className="required form-label">{executionType === ExecutionTypeEnum.Executable ? t('form.label.executable') : t('form.label.apiAddress')}</label>
										<input
											type="text"
											name="executable"
											className="form-control form-control-lg form-control-solid"
											placeholder={executionType === ExecutionTypeEnum.Executable ? t('form.placeholder.executable') : t('form.placeholder.apiAddress')}
											value={formik.values.executable ?? ''}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
										/>
										{formik.touched.executable && formik.errors.executable && (
											<div className="mt-3 text-danger fw-bold">{formik.errors.executable}</div>
										)}
									</div>
									<div className="col-md-6 mb-10">
										<label className="form-label">{executionType === ExecutionTypeEnum.Executable ? t('form.label.arguments') : t('form.label.queryString')}</label>
										<input
											type="text"
											name="arguments"
											className="form-control form-control-lg form-control-solid"
											placeholder={executionType === ExecutionTypeEnum.Executable ? t('form.placeholder.arguments') : t('form.placeholder.queryString')}
											value={formik.values.arguments ?? ''}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
										/>
										{formik.touched.arguments && formik.errors.arguments && (
											<div className="mt-3 text-danger fw-bold">{formik.errors.arguments}</div>
										)}
									</div>
									{executionType === ExecutionTypeEnum.Executable ? (
										<>
											{uploadIsAllowed ?
												<div className="col-md-12 mb-10">
													<label className="required form-label">{t('form.label.formFile')}</label>
													<input
														className="form-control form-control-lg form-control-solid"
														type="file"
														name="formFile"
														onChange={(e) => {
															formik.handleChange(e)
															onFileChange(e)
														}}
													/>
												</div>
												:
												<div className="col mb-10">
													<label className="required form-label">{t('form.label.filePath')}</label>
													<div className="input-group">
														<input
															type="text"
															name="filePath"
															className="form-control form-control-lg form-control-solid"
															placeholder={t('form.placeholder.filePath')}
															value={formik.values.filePath ?? ''}
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
														/>
														<Tooltip title={tooltipText} placement="top" arrow>
															<button
																type="button"
																className="btn btn-bg-light btn-active-color-primary"
																onClick={() => {
																	navigator.clipboard.writeText(formik.values.filePath ?? "");
																	setTooltipText(t("generalMessages.copied"));

																	setTimeout(() => {
																		setTooltipText(t("generalMessages.copy"));
																	}, 2000);
																}}
															>
																<ITSVG path={copyIcon} className="svg-icon-1" />
															</button>
														</Tooltip>
													</div>
													{formik.touched.filePath && formik.errors.filePath && (
														<div className="mt-3 text-danger fw-bold">{formik.errors.filePath}</div>
													)}
												</div>
											}
											{props.id > 0 && (
												<div className="col-md-3">
													<label className="form-label">{t('form.label.status')}</label>
													<div className="form-check form-check-solid form-switch">
														<input
															className="form-check-input w-45px h-30px"
															type="checkbox"
															id="isActive"
															name="isActive"
															checked={formik.values.isActive ?? false}
															onChange={formik.handleChange}
														/>
													</div>
													{formik.values.isActive ? (
														<div className="text-muted fs-7 mt-5">{t('generalMessages.active')}</div>
													) : (
														<div className="text-muted fs-7 mt-5">{t('generalMessages.inactive')}</div>
													)}
												</div>
											)}
											<div className="col-md-3">
												<label className="form-label">{t('form.label.allowParallelExecution')}</label>
												<Tooltip title={t('form.tooltip.allowParallelExecution')} placement="top" arrow>
													<div className="form-check form-check-solid form-switch">
														<input
															className="form-check-input w-45px h-30px"
															type="checkbox"
															id="allowParallelExecution"
															name="allowParallelExecution"
															checked={formik.values.allowParallelExecution ?? false}
															onChange={formik.handleChange}
														/>
													</div>
												</Tooltip>
												{formik.values.allowParallelExecution ? (
													<div className="text-muted fs-7 mt-5">{t('generalMessages.active')}</div>
												) : (
													<div className="text-muted fs-7 mt-5">{t('generalMessages.inactive')}</div>
												)}
											</div>
										</>
									) : (
										<>
											<div className="col-md-6 mb-10">
												<label className="required form-label">{t('form.label.httpMethod')}</label>
												<Autocomplete
													id="httpMethod"
													disablePortal
													options={Object.keys(HttpMethodEnum).filter((key) => isNaN(Number(key)) && key !== 'None')}
													value={
														httpMethodDefaultValue !== null && httpMethodDefaultValue !== undefined && httpMethodDefaultValue !== HttpMethodEnum.None
															? HttpMethodEnum[httpMethodDefaultValue.toString() as keyof typeof HttpMethodEnum].toString()
															: null
													}
													getOptionLabel={option => option ? option.toString() : ""}
													onChange={(_, value) => {
														setHttpMethodDefaultValue(HttpMethodEnum[value as keyof typeof HttpMethodEnum])
														formik.setFieldValue("httpMethod", value)
													}}
													onInputChange={(_, value) => {
														if (!value) {
															setHttpMethodDefaultValue(undefined)
															formik.setFieldValue("httpMethod", undefined)
														}
													}}
													onBlur={() => formik.setFieldTouched("httpMethod")}
													renderInput={(params) =>
														<TextField
															{...params}
															placeholder={`${t('generalMessages.select')}`}
														/>
													}
												/>
												{formik.touched.httpMethod && formik.errors.httpMethod && (
													<div className="mt-3 text-danger fw-bold">{formik.errors.httpMethod}</div>
												)}
											</div>
											<div className="col-md-6 mb-10">
												<label className="form-label">{t('form.label.finalURL')}</label>
												<div className='form-label ms-10'>
													{formik.values.executable ?
														`${formik.values.executable}${formik.values.arguments ? `?${formik.values.arguments}` : ''}
														${formik.values.httpMethod || formik.values.httpMethod === 0? 
															Number.isInteger(formik.values.httpMethod)?
															 `   {${HttpMethodEnum[formik.values.httpMethod]}}` :`   {${formik.values.httpMethod}}`
															 : ''}`
														: ''
													}
												</div>
											</div>
											<div className="col-md-12 mb-10">
												<label className="form-label">{t('form.label.stepHttpHeaders')}</label>
											</div>
											<div className="row">
												<div className="col-md-5 mb-10">
													<label className="form-label">{t('form.label.key')}</label>
													<input
														type="text"
														name="key"
														className="form-control form-control-lg form-control-solid"
														placeholder={t('form.placeholder.key')}
														value={formik.values.key ?? ''}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
													/>
												{formik.touched.key && formik.errors.key && (
													<div className="mt-3 text-danger fw-bold">{formik.errors.key}</div>
												)}
												</div>
												<div className="col-md-5 mb-10">
													<label className="form-label">{t('form.label.value')}</label>
													<input
														type="text"
														name="value"
														className="form-control form-control-lg form-control-solid"
														placeholder={t('form.placeholder.value')}
														value={formik.values.value}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
													/>
												{formik.touched.value && formik.errors.value && (
													<div className="mt-3 text-danger fw-bold">{formik.errors.value}</div>
												)}
												</div>
												<div className="col-md-2 mt-9">
													<button
														type="button"
														className="btn btn-primary"
														disabled={!!formik.errors.value || !!formik.errors.key}
														onClick={() => addKeyValuePair(formik)}
													>
														{t('form.label.add')}
													</button>
												</div>
											</div>
											
											<div className="col-md-12 mb-10">
												<DataTable
													noDataComponent={<label className='form-label'>{t('form.table.noHttpHeaders')}</label>}
													persistTableHead={true}
													columns={columns}
													data={formik.values.stepHttpHeaders || []}
													highlightOnHover
													pointerOnHover
													customStyles={itLabTableStyle}
												/>
											</div> 
										</>
									)}
								</>
							)}
						</div>
					</div>
					<div className="modal-footer d-flex justify-content-end py-6 px-9">
						<button type="button" className="btn btn-light btn-active-light-primary me-2" onClick={handleCancel}>
							{t('crud.create.buttonCancel')}
						</button>
						<SaveButton type="submit" loading={formik.isSubmitting} />
					</div>
				</form>
			</div>
		</Modal>
	);
}
