import { StatusFilter } from 'common/types/status-filter.model';

export enum OperationSystemSessionState {
  Lock = 0,
  Unlock = 1
}

export interface MachineModel {
  id?: number;
  name?: string;
  machineType?: MachineTypeModel;
  operationSystem?: OperationSystemModel;
  qtProcess?: string;
  percentage?: string;
  isActive?: boolean;
  site?: string;
  ip?: string;
  technologies?: string;
  processStep?: string;
  lastSuccessRun?: string;
  nextPlannedRun?: string;
  siteId?: number;
  machineTypeId?: number;
  operationSystemId?: number;
  machineId?: number;
  technologyId?: number;
  processId?: number;
  port?: number;
  isAutoCreated?: boolean;
  version?: string;
  operationSystemSessionState?: OperationSystemSessionState;
  isKeepSessionActive?: boolean;
}

export interface MachineBaseViewModel {
  id: number;
  name: string;
}

export interface MachineAvailableNowViewModel extends MachineBaseViewModel {
  isUp: boolean;
}

export interface AvailableMachinesRequestViewModel {
  startDate: string;
  endDate: string;
  processId: number;
}

interface OperationSystemModel {
  id?: number;
  name?: string;
  description?: string;
  machines?: string[];
  isActive?: boolean;
}

interface MachineTypeModel {
  id?: number;
  name?: string;
  description?: string;
  machines?: string[];
  isActive?: boolean;
}

export interface TechnologyUpdateModel {
  machineId: number;
  technologyId: number;
}

export interface ProcessUpdateModel {
  machineId: number;
  processId: number;
}

export interface ScreenshotList {
  folder: string;
  details: ScreenshotDetailList[];
}

export interface ScreenshotDetailList {
  fileName: string;
  folderName: string;
  fullName: string;
  createdDate: Date;
}

export interface MachineQuery {
  status?: StatusFilter;
  pageSize: number;
  pageNumber: number;
  orderBy?: string;
  order?: 'asc' | 'desc';
}

export interface MachineListModel {
  pageSize: number;
  pageNumber: number;
  totalCount: number;
  items: MachineModel[];
}