import { http } from 'common/helpers/axios/axios-helper';
import { 
  NotificationRequest, 
  DepartmentModel, 
  NotificationPostViewModel,
  NotificationModel
} from '../model/notification.model';

const basePath = '/notification';

async function getDataList(request: NotificationRequest) {
  let query: string = `?type=${request.notificationType || ''}`;
  query = `${query}&search=${request.search || ''}`
  query = `${query}&read=${request.isRead === undefined ? '' : request.isRead}`
  query = `${query}&order=${request.order}`
  query = `${query}&orderBy=${request.sort}`
  query = `${query}&pageNumber=${request.pageIndex}`
  query = `${query}&pageSize=${request.pageSize}`
  return await http.get<NotificationModel[]>(`${basePath}${query}`);
}

async function getNotificationById(id: string) {
  return await http.get<NotificationModel | undefined>(`${basePath}/${id}`);
}

async function getDepartments() {
  return await http.get<DepartmentModel[]>(`${basePath}/departments`);
}

async function postNotification(request: NotificationPostViewModel){
  return await http.post(`${basePath}`, request);
}

async function putNotification(id: number, request: NotificationPostViewModel){
  return await http.put(`${basePath}/${id}`, request);
}

async function markAsRead(model: NotificationModel) {
  return await http.get(`${basePath}/markAsRead/${model.id}`);
}

async function deleteNotification(notification: NotificationModel) {
  return await http.del(`${basePath}/${notification.id}`);
} 

async function getNotificationPinned() {
  return await http.get(`${basePath}/getPinned`);
}

export const NotificationService = {
    getDataList,
    getDepartments,
    postNotification,
    putNotification,
    markAsRead,
    deleteNotification,
    getNotificationById,
    getNotificationPinned
}