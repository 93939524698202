import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import DataTable, { TableColumn } from 'react-data-table-component';
import { toast } from 'react-toastify';
import { tableHelper } from 'common/helpers/table/table-helper';
import { StatusFilterDropdown } from 'common/partials/StatusFilterDropdown';
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from 'common/imports/content-layout';
import { DeleteModal } from 'common/partials/DeleteModal';
import { itLabTableStyle } from 'common/helpers/table/table-style';
import { RolesDictionary } from 'common/helpers/dictionaries/roles-dictionary';
import ProtectedComponent from 'common/partials/ProtectedComponent';
import ActionButton from 'common/partials/ActionButton';
import { StatusFilter } from 'common/types/status-filter.model';
import { MachineModel as Model, OperationSystemSessionState } from '../model/machine.model';
import { MachineService as Service } from '../services/machine.service';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';

export function Machine() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<Model[]>([]);
  const [filteredTableData, setFilteredTableData] = useState<Model[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Model[]>([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(100);
  const [sorting, setSorting] = useState<{ orderBy?: string; order?: 'asc' | 'desc' }>({});

  const handleShowDeleteModal = (id: number) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await Service.getDataList(
        StatusFilter.Active,
        currentPage,
        rowsPerPage,
        sorting.orderBy,
        sorting.order
      );
      
      if (response && !response.hasErrors) {
        setFilteredTableData(response.data.items);
        setTotalRows(response.data.totalCount);
      }
    } catch (err) {
      toast.error(`${t('crud.read.errorMessage')}`);
    } finally {
      setIsLoading(false);
    }
  };

  const operationSystemSessionStateTags = {
    0: (<span className="badge text-bg-danger">{t('table.contents.osstate.lock')}</span>),
    1: (<span className="badge text-bg-success text-white">{t('table.contents.osstate.unlock')},
    2: (<span className="badge text-bg-secondary">{t('table.contents.osstate.unknown')}</span>),</span>)
  };

  const columns: TableColumn<Model>[] = [
    {
      name: `${t('table.id')}`,
      selector: (row) => row.id || '',
      sortable: true,
      sortField: 'Id',
      width: '40px'
    },
    {
      cell: (row) =>
        row.isAutoCreated && !row.isActive && (
          <i className="fa-solid fa-robot"></i>
        ),
      width: '30px'
    },
    {
      name: `${t('table.name')}`,
      selector: (row) => row.name || '',
      sortable: true,
      sortField: 'Name'
    },
    {
      name: `${t('table.machineType')}`,
      selector: (row) => row.machineType?.name || '',
      sortable: true,
      sortField: 'MachineTypeId'
    },
    {
      name: `${t('table.os')}`,
      selector: (row) => row.operationSystem?.name || '',
      sortable: true,
      sortField: 'OperationSystemId'
    },
    {
      name: `${t('table.osstate')}`,
      cell: (row) => (
        row.operationSystemSessionState !== undefined ?
          row.operationSystemSessionState.valueOf() === OperationSystemSessionState.Lock.valueOf() 
            ? (<span className="badge text-bg-danger">{t('table.contents.osstate.lock')}</span>)
            : (<span className="badge text-bg-success text-white">{t('table.contents.osstate.unlock')}</span>)
          : (<span className="badge text-bg-secondary">{t('table.contents.osstate.unknown')}</span>)
      ),
      width: '150px',
      center: true,
    },
    {
      name: `${t('table.version')}`,
      selector: (row) => row.version || '',
      width: '150px',
      center: true,
    },
    {
      name: `${t('table.status')}`,
      cell: (row) =>
        row.isActive ? (
          <span className="badge badge-light-primary">{t('generalMessages.active')}</span>
        ) : (
          <span className="badge badge-light-danger">{t('generalMessages.inactive')}</span>
        ),
      center: true,
      width: '150px'
    },
    {
      name: `${t('table.actions')}`,
      cell: (row) => (
        <div className="d-grid d-flex gap-2">
          <ActionButton type='edit' onClick={() => navigate(`/infrastructure/machine/${row.id}`)} roles={[RolesDictionary.Infrastructure.Machines_Update]} />
          <ActionButton type='deactivate' onClick={() => handleShowDeleteModal(row.id!)} roles={[RolesDictionary.Infrastructure.Machines_Update]} disabled={!row.isActive} />
        </div>
      ),
      center: true,
      width: '100px'
    },
  ];

  const handleRowSelected = useCallback((state: any) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const deleteDataById = async () => {
    try {
      setIsLoading(true);
      const result = await Service.deleteDataById(deleteId);
      if (result && !result.hasErrors) {
        toast.success(`${t('crud.deactivate.successMessage')}`);
      } else {
        toast.error(`${t('crud.deactivate.errorMessage')}`);
      }
    } catch (err) {
      toast.error(`${t('crud.read.errorMessage')}`);
    } finally {
      setToggleCleared(!toggleCleared);
      handleCloseDeleteModal();
      fetchData();
      setIsLoading(false);
    }
  };

  const contextActions = useMemo(() => {
    const deleteSelectedRows = () => {
      setShowDeleteModal(true);
      selectedRows.forEach((row) => {
        setDeleteId(row.id!);
      });
    }

    return (
      <button type="button" className="btn btn-sm btn-danger" onClick={deleteSelectedRows}>
        {t('crud.deactivate.buttonSave')}
      </button>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows, toggleCleared]);


  function FilterByName(filter: string) {
    if (filter === '') {
      setFilteredTableData(tableData.filter((item) => item.isActive));
      return;
    }

    const normalizedFilter = filter.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    setFilteredTableData(
      tableData.filter(
        (item) =>
          item.name
            ?.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .includes(normalizedFilter.toLowerCase()) ||
          item.id?.toString().includes(filter),
      ),
    );
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, rowsPerPage, sorting]);

  const handleListSort = (selectedColumn: TableColumn<Model>, sortDirection: 'asc' | 'desc') => {
    setTableData([]);
    setSorting({
      orderBy: selectedColumn.sortField,
      order: sortDirection
    });
  };

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight>
          <div className="d-flex align-items-center gap-2">
            <StatusFilterDropdown handleFilterData={fetchData} />
            <ProtectedComponent action='hide' role={[RolesDictionary.Infrastructure.Machines_Update]}>
              <Link to="/infrastructure/machine/add" className="btn btn-sm btn-primary fw-bolder">
                {t('addDataButton')}
              </Link>
            </ProtectedComponent>
          </div>
        </ToolbarLayoutRight>
      </ToolbarLayout>
      <MainLayout>
        <div className="card card-flush">
          <div className="card-body">
            <DataTable
              title={tableHelper.getTableHeader((e: any) => FilterByName(e.target.value))}
              persistTableHead
              columns={columns}
              data={filteredTableData}
              noDataComponent={tableHelper.getNoDataComponent()}
              selectableRows
              selectableRowsHighlight
              dense
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
              paginationPerPage={rowsPerPage}
              paginationRowsPerPageOptions={tableHelper.paginationRowsPerPageOptions}
              onChangePage={(page) => setCurrentPage(page)}
              onChangeRowsPerPage={(newPerPage, page) => {
                setRowsPerPage(newPerPage);
                setCurrentPage(page);
              }}
              highlightOnHover
              progressPending={isLoading}
              progressComponent={tableHelper.getLoader()}
              contextActions={contextActions}
              contextMessage={tableHelper.getContextMessage()}
              onSelectedRowsChange={handleRowSelected}
              clearSelectedRows={toggleCleared}
              selectableRowsVisibleOnly
              customStyles={itLabTableStyle}
              onSort={handleListSort}
            	sortServer
            />
          </div>
        </div>

        <DeleteModal
          type='deactivate'
          handleDelete={deleteDataById}
          onClose={handleCloseDeleteModal}
          open={showDeleteModal}
        />
      </MainLayout>
    </MasterLayout>
  );
}
