import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import DataTable, { SortOrder, TableColumn } from 'react-data-table-component';
import { Tooltip } from '@mui/material';
import { MasterLayout, ToolbarLayout, ToolbarLayoutLeft, ToolbarLayoutRight, MainLayout } from 'common/imports/content-layout';
import { tableHelper } from 'common/helpers/table/table-helper';
import { itLabTableStyle } from 'common/helpers/table/table-style';
import { formatLocalizedDateTime } from 'common/helpers/dateUtils';
import { useSignalR } from 'common/helpers/signalR/context/signalR.hook';
import { PagingOptionsModel } from 'common/types/paging-options.model';
import { getStatusColor, getStatusText, getTimeLineBadge } from 'app/dashboard/utils/dashboardUtils';
import { AgentLogStatus } from 'app/dashboard/model/dashboard.model';
import { RobotExecutions, RobotExecutionsErrorReason, RobotExecutionsRequest } from '../models/robot-executions.model';
import { RobotExecutionsService } from '../services/robot-executions.service';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';
import { RobotExecutionsFilterDropdown } from './RobotExecutionsFilterDropdown';
import ErrorDescriptionModal from './ErrorDescriptionModal';
import { useFilterParams } from '../hooks/use-filter-params';

export default function RobotExecutionsList() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);

  const { t } = useTranslation();

  const { status, errorReason } = useFilterParams();

  const [tableData, setTableData] = useState<RobotExecutions[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isErrorDescriptionModalOpened, setIsErrorDescriptionModalOpened] = useState<boolean>(false);
  const [errorReasonSelected, setErrorReasonSelected] = useState<RobotExecutionsErrorReason>();

  const [totalRows, setTotalRows] = useState<number>(0);
  const [message, setMessage] = useState('');

  const defaultFilters: RobotExecutionsRequest = {
    status: status,
    errorReason: errorReason
  }

  const [filterValues, setFilterValues] = useState<RobotExecutionsRequest | undefined>(defaultFilters);
  const [sorting, setSorting] = useState<PagingOptionsModel>({
    page: 0,
    limit: tableHelper.defaultRowsPerPage
  });

  const { hubConnection } = useSignalR();

  const buildTooltipContent = (custom: string | null): JSX.Element[] | null => {
    if (!custom) {
      return null;
    }

    let content: JSX.Element[] = [];

    try {
      if (custom) {
        const jsonObject = JSON.parse(custom);

        Object.keys(jsonObject).forEach((key) => {
          const normalizedKey = key.toLowerCase();
          if (normalizedKey === 'endprocess' || normalizedKey === 'restartprocess') {
            delete jsonObject[key];
          }
        });

        content = Object.keys(jsonObject).map((key, index) => (
          <div className='fs-8 fw-bold my-2 me-3 d-block' key={index}>{key}: {jsonObject[key]}</div>
        ));
      }
    } catch (error) {
      content.push(<div className='fs-8 fw-bold my-2 me-3 d-block' key='error'>?: ?</div>);
    }

    return content;
  };

  const openErrorDescriptionModal = (agenteLogId: number, errorReason?: RobotExecutionsErrorReason) => {
    if (!errorReason) {
      errorReason = {
        id: 0,
        agentLogId: agenteLogId,
        errorReasonId: 0,
        name: "",
        reason: ""
      }
    }
    setErrorReasonSelected(errorReason);
  }

  const closeErrorDescriptionModal = () => {
    setErrorReasonSelected(undefined);
    fetchData();
  }

  useEffect(() => {
    setIsErrorDescriptionModalOpened(errorReasonSelected !== undefined);
  }, [errorReasonSelected])

  const buildBadge = (row: RobotExecutions): JSX.Element => {
    return (
      <Tooltip title={buildTooltipContent(row.custom)} arrow>
        <>
          <span className={`badge badge-light-${getStatusColor(row.status)}`}>
            <i className={`${getTimeLineBadge(row.status)} me-2`}></i>
            {getStatusText(row.status, t)}
          </span>
          {row.status === AgentLogStatus.Error &&
            <button
              type="button"
              className="btn btn-icon btn-link"
              onClick={() => openErrorDescriptionModal(row.id, row.errorReason)}
            >
              <span>
                <i className="bi bi-pencil-fill fs-7"></i>
              </span>
            </button>
          }
        </>
      </Tooltip>
    );
  };

  const columns: TableColumn<RobotExecutions>[] = [
    {
      name: `${t('robotExecutions.tableHeaders.stepName')}`,
      cell: (row) => (
        <Tooltip title={<span className='fs-8'>{t('robotExecutions.tableHeaders.processName')}: {row.processName}</span>} arrow>
          <span>{row.stepName}</span>
        </Tooltip>
      ),
      sortable: false,
      minWidth: '200px'
    },
    {
      name: `${t('robotExecutions.tableHeaders.machineName')}`,
      cell: (row) => row.machineIsUp
        ? (
          <Tooltip title={t('generalMessages.connected')}>
            <span>
              <i className='fa-solid fa-link text-success me-2' />
              {row.machineName}
            </span>
          </Tooltip>
        ) : (
          <Tooltip title={t('generalMessages.disconnected')}>
            <div>
              <i className='fa-solid fa-link-slash text-danger me-2' />
              {row.machineName}
            </div>
          </Tooltip>
        ),
      sortable: false
    },
    {
      name: `${t("robotExecutions.tableHeaders.departmentName")}`,
      cell: (row) => row.departments?.map((dp, index) => <span className="badge badge-light-primary fs-8 fw-bold my-2 me-3" key={index}>{dp}</span>),
      sortable: false,
      minWidth: '320px',
    },
    {
      name: `${t('robotExecutions.tableHeaders.start')}`,
      selector: (row) => formatLocalizedDateTime(row.start, 'llll') || '',
      sortable: true,
      sortField: 'start',
      minWidth: '250px'
    },
    {
      name: `${t('robotExecutions.tableHeaders.end')}`,
      selector: (row) => row.end ? formatLocalizedDateTime(row.end, 'lll') || '-' : '-',
      sortable: true,
      sortField: 'end',
      minWidth: '220px'
    },
    {
      name: `${t('robotExecutions.tableHeaders.reason')}`,
      cell: (row) => row.errorReason ?
        (<Tooltip title={row.errorReason.reason}>
          <span>
            {row.errorReason.name}
          </span>
        </Tooltip>)
        :
        <span></span>,
      sortable: false,
      minWidth: '220px'
    },
    {
      name: `${t('robotExecutions.tableHeaders.status')}`,
      cell: (row) => buildBadge(row),
      center: true,
      width: '150px'
    },
  ];

  const fetchData = async () => {
    const response = await RobotExecutionsService.getExecutionLog({
      page: sorting.page,
      limit: sorting.limit,
      sort: sorting?.sort,
      order: sorting?.order,
      status: filterValues?.status,
      errorReason: filterValues?.errorReason,
      departmentId: filterValues?.departmentId,
      machineId: filterValues?.machineId,
      stepId: filterValues?.stepId
    });

    if (response && !response.hasErrors) {
      setTableData(response.data);
      setTotalRows(response.totalItems);
    }

    setIsLoading(false);
  };

  const handleRowsPerPageChange = async (newRowsPerPage: number) => {
    setTableData([]);
    setSorting({
      ...sorting,
      limit: newRowsPerPage
    });
  }

  const handlePageChange = (newPage: number) => {
    setSorting({
      ...sorting,
      page: newPage - 1
    });
  };

  const handleListSort = (selectedColumn: TableColumn<RobotExecutions>, sortDirection: SortOrder) => {
    setTableData([]);
    setSorting({
      ...sorting,
      sort: selectedColumn.sortField,
      order: sortDirection
    });
  }

  useEffect(() => {
    const receiveMessages = async () => {
      hubConnection?.on('GetStepLog', message => setMessage(message));
      hubConnection?.on('UpdateConnection', message => setMessage(message));
    };
    receiveMessages();

    return () => {
      hubConnection?.off('GetStepLog');
      hubConnection?.off('UpdateConnection');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubConnection]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, sorting, message])

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight>
          <div className='d-flex align-items-center gap-2'>
            <RobotExecutionsFilterDropdown
              filters={filterValues}
              setFilters={setFilterValues}
            />
          </div>
        </ToolbarLayoutRight>
      </ToolbarLayout>
      <MainLayout>
        <div className='card card-flush'>
          <div className='card-body'>
            <DataTable
              title={false}
              persistTableHead
              columns={columns}
              data={tableData}
              noDataComponent={tableHelper.getNoDataComponent()}
              dense
              highlightOnHover
              pagination
              paginationServer
              paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
              paginationPerPage={tableHelper.defaultRowsPerPage}
              paginationRowsPerPageOptions={tableHelper.paginationRowsPerPageOptions}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handleRowsPerPageChange}
              onChangePage={handlePageChange}
              onSort={handleListSort}
              sortServer
              progressPending={isLoading}
              progressComponent={tableHelper.getLoader()}
              customStyles={itLabTableStyle}
            />
          </div>
        </div>
        <ErrorDescriptionModal agenteLogErrorReason={errorReasonSelected} onClose={closeErrorDescriptionModal} show={isErrorDescriptionModalOpened} />
      </MainLayout>
    </MasterLayout>
  )
}