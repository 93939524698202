/* eslint-disable react/jsx-no-target-blank */
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { AsideMenuItem } from './AsideMenuItem';
import { APP_ROUTES } from 'routes/routes';
import { useTranslation } from 'react-i18next';
import dashboardIcon from 'assets/images/icons/dashboard.svg';
import securityIcon from 'assets/images/icons/security.svg';
import settingsIcon from 'assets/images/icons/settings.svg';
import processesIcon from 'assets/images/icons/process.svg';
import table from 'assets/images/icons/fil002.svg';
import computer from 'assets/images/icons/elc004.svg';
import FeatureFlag from 'common/partials/FeatureFlag';
import FeatureFlagEnum from 'common/enums/FeatureFlagEnum';

export function AsideMenuMain() {
	const { t } = useTranslation();

	return (
		<>
			<AsideMenuItem
				to="/dashboard"
				icon={dashboardIcon}
				title={`${t('menu.dashboard')}`}
				fontIcon="bi-app-indicator"
			/>

			<div className="menu-item">
				<div className="menu-content pt-8 pb-2">
					<span className="menu-section text-muted text-uppercase fs-8 ls-1">{t('menu.general')}</span>
				</div>
			</div>
			<AsideMenuItemWithSub to="/masterdata" title={`${t('menu.masterData')}`} fontIcon="bi-archive" icon={table}>
				<AsideMenuItem
					to={'/' + APP_ROUTES.masterdata + '/' + APP_ROUTES.departaments.list}
					title={`${t('menu.departaments.menu')}`}
					hasBullet={true}
				/>
				<AsideMenuItem
					to={'/' + APP_ROUTES.masterdata + '/' + APP_ROUTES.sites.list}
					title={`${t('menu.sites.menu')}`}
					hasBullet={true}
				/>
				<AsideMenuItem
					to={'/' + APP_ROUTES.masterdata + '/' + APP_ROUTES.countries.list}
					title={`${t('menu.countries.title')}`}
					hasBullet={true}
				/>
				<AsideMenuItem
					to={'/' + APP_ROUTES.masterdata + '/' + APP_ROUTES.operationsystem.list}
					title={`${t('menu.operationsystem.title')}`}
					hasBullet={true}
				/>
				<AsideMenuItem
					to={'/' + APP_ROUTES.masterdata + '/' + APP_ROUTES.technologies.list}
					title={`${t('menu.technologies.menu')}`}
					hasBullet={true}
				/>
				<AsideMenuItem
					to={'/' + APP_ROUTES.masterdata + '/' + APP_ROUTES.machineType.list}
					title={`${t('menu.machineType.title')}`}
					hasBullet={true}
				/>
				<AsideMenuItem
					to={'/' + APP_ROUTES.masterdata + '/' + APP_ROUTES.robotType.list}
					title={`${t('menu.robotType.menu')}`}
					hasBullet={true}
				/>
				<AsideMenuItem
					to={'/' + APP_ROUTES.masterdata + '/' + APP_ROUTES.errorreasons.list}
					title={`${t('menu.errorreason.menu')}`}
					hasBullet={true}
				/>
			</AsideMenuItemWithSub>

			<AsideMenuItemWithSub
				to="/infrastructure"
				title={`${t('menu.infrastructure')}`}
				fontIcon="bi-archive"
				icon={computer}
			>
				<AsideMenuItem
					to={'/' + APP_ROUTES.infrastructure + '/' + APP_ROUTES.machineActivity.list}
					title={`${t('menu.machineActivity')}`}
					hasBullet={true}
				/>
				<AsideMenuItem
					to={'/' + APP_ROUTES.infrastructure + '/' + APP_ROUTES.machine.list}
					title={`${t('menu.machine.title')}`}
					hasBullet={true}
				/>
			</AsideMenuItemWithSub>

			<AsideMenuItemWithSub
				to="/processes"
				title={`${t('menu.processes.title')}`}
				fontIcon="bi-archive"
				icon={processesIcon}
			>
				<AsideMenuItem
					to={'/' + APP_ROUTES.processes + '/' + APP_ROUTES.schedule}
					title={`${t('menu.schedule')}`}
					hasBullet={true}
				/>
				<AsideMenuItem
					to={'/' + APP_ROUTES.processes + '/' + APP_ROUTES.process.list}
					title={`${t('menu.processes.menu')}`}
					hasBullet={true}
				/>

				<AsideMenuItem
					to={'/' + APP_ROUTES.processes + '/' + APP_ROUTES.robotExecutions.list}
					title={`${t('menu.executions.menu')}`}
					hasBullet={true}
				/>
			</AsideMenuItemWithSub>

			<AsideMenuItemWithSub to="/security" title={`${t('menu.security')}`} fontIcon="bi-shield-alt" icon={securityIcon}>
				<AsideMenuItem
					to={'/' + APP_ROUTES.security + '/' + APP_ROUTES.tenantmanagement.list}
					title={`${t('menu.tenantManagement.title')}`}
					hasBullet={true}
				/>
				<AsideMenuItem
					to={'/' + APP_ROUTES.security + '/' + APP_ROUTES.groupmanagement.list}
					title={`${t('menu.groupManagement.title')}`}
					hasBullet={true}
				/>
				<AsideMenuItem
					to={'/' + APP_ROUTES.security + '/' + APP_ROUTES.usermanagement.list}
					title={`${t('menu.userManagement.title')}`}
					hasBullet={true}
				/>
			</AsideMenuItemWithSub>

			<div className="menu-item">
				<div className="menu-content pt-8 pb-2">
					<span className="menu-section text-muted text-uppercase fs-8 ls-1">{`${t('menu.settings')}`}</span>
				</div>
			</div>

			<AsideMenuItemWithSub to="/settings" title={`${t('menu.settings')}`} fontIcon="bi-chat-left" icon={settingsIcon}>
				<AsideMenuItem
					to={'/' + APP_ROUTES.settings + '/' + APP_ROUTES.apiClients}
					title={`${t('menu.apiClients')}`}
					hasBullet={true}
				/>
				<AsideMenuItem
					to={'/' + APP_ROUTES.settings + '/' + APP_ROUTES.signInAudit}
					title={`${t('menu.signInAudit.title')}`}
					hasBullet={true}
				/>
				<AsideMenuItem
					to={'/' + APP_ROUTES.settings + '/' + APP_ROUTES.generalsettings}
					title={`${t('menu.generalSettings')}`}
					hasBullet={true}
				/>
				<AsideMenuItem
					to={'/' + APP_ROUTES.settings + '/' + APP_ROUTES.healthcheck}
					title={`${t('menu.healthCheck')}`}
					hasBullet={true}
				/>
				<FeatureFlag feature={FeatureFlagEnum.DownloadAgent}>
					<AsideMenuItem
						to={'/' + APP_ROUTES.settings + '/' + APP_ROUTES.agentVersions}
						title={`${t('menu.agentVersions')}`}
						hasBullet={true}
					/>
				</FeatureFlag>
			</AsideMenuItemWithSub>

			<div className="menu-item">
				<div className="menu-content">
					<div className="separator mx-1 my-4"></div>
				</div>
			</div>
		</>
	);
}
