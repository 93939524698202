import { http } from 'common/helpers/axios/axios-helper';
import { SignInAuditModel, SignInAuditQuery as Query } from '../model/sign-in-audit.model';

const basePath = 'user/signin/audit';

async function getDataList(query?: Query & { orderBy?: string; order?: 'asc' | 'desc' }) {
	const queryString = query ? `?pageNumber=${query.pageNumber}&pageSize=${query.pageSize}&orderBy=${query.orderBy}&order=${query.order}` : '';
	const response =  await http.get<SignInAuditModel>(`${basePath}${queryString}`);
	if (response) {
	  return response;
	} else {
	  return { data: {items: [], totalCount: 0, pageNumber: query?.pageNumber, pageSize: query?.pageSize}, total: 0, hasErrors: true }; 
	}
}

export const SignInAuditService = { getDataList };
