import i18next from 'i18next';
import DataTable, { TableColumn } from 'react-data-table-component';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { tableHelper } from 'common/helpers/table/table-helper';
import {
	MasterLayout,
	MainLayout,
	ToolbarLayout,
	ToolbarLayoutLeft,
	ToolbarLayoutRight,
} from 'common/imports/content-layout';
import { itLabTableStyle } from 'common/helpers/table/table-style';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';
import { SignInAuditService as Service } from '../services/sign-in-audit.services';
import { SignInAuditQuery as Query, SignInAuditItem } from '../model/sign-in-audit.model';
import { formatLocalizedDateTime } from 'common/helpers/dateUtils';

export function SignInAudit() {
	i18next.addResourceBundle('us', 'translation', en);
	i18next.addResourceBundle('br', 'translation', pt);
	i18next.addResourceBundle('es', 'translation', es);

	const { t } = useTranslation();
	const [tableData, setTableData] = useState<SignInAuditItem[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [totalRows, setTotalRows] = useState(100);
	const [sorting, setSorting] = useState<{ orderBy?: string; order?: 'asc' | 'desc' }>({
		orderBy: 'When',
		order: 'desc'
	});

	const fetchData = async (query?: Query) => {
		try {
		  setIsLoading(true);
		  const response = await Service.getDataList({ 
			...query, 
			pageNumber: currentPage, 
			pageSize: rowsPerPage,
			orderBy: sorting.orderBy,
			order: sorting.order
		  });
	  
		  if (response && !response.hasErrors) {
			setTableData(response.data.items);
			setTotalRows(response.data.totalCount);
		  }
		} catch (err) {
		  toast.error(`${t('crud.read.errorMessage')}`);
		} finally {
		  setIsLoading(false);
		}
	  };	  

	const columns: TableColumn<SignInAuditItem>[] = [
		{
			name: `${t('table.when')}`,
			selector: (row) => formatLocalizedDateTime(row.when.toLocaleString(), 'llll') || '',
			sortable: true,
			sortField: 'When',
			width: "300px"
		},
		{
			name: `${t('table.username')}`,
			selector: (row) => row.userName || '',
			sortable: true,
			sortField: 'UserName'
		},
		{
			name: `${t('table.tenant')}`,
			selector: (row) => row.tenant || '',
			sortable: false
		},
		{
			name: `${t('table.success')}`,
			selector: (row) => row.success ? t('table.success.true') : t('table.success.false'),
			sortable: true,
			sortField: 'Success'
		},
		{
			name: `${t('table.ip')}`,
			selector: (row) => row.ipv4 && row.ipv4.trim() !== '' ? row.ipv4 : row.ipv6 || '',
			sortable: false
		},
	];

	useEffect(() => {
		fetchData();
	}, [currentPage, rowsPerPage, sorting]);

	const handleListSort = (selectedColumn: TableColumn<SignInAuditItem>, sortDirection: 'asc' | 'desc') => {
		setTableData([]);
		setSorting({
		  orderBy: selectedColumn.sortField,
		  order: sortDirection 
		});
	  };

	return (
		<MasterLayout>
			<ToolbarLayout>
				<ToolbarLayoutLeft />
				<ToolbarLayoutRight />
			</ToolbarLayout>
			<MainLayout>
				<div className="card card-flush">
					<div className="card-body">
					<DataTable
						columns={columns}
						data={tableData}
						noDataComponent={tableHelper.getNoDataComponent()}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						paginationPerPage={rowsPerPage}
						paginationRowsPerPageOptions={[10, 20, 30, 50]}
						onChangePage={(page) => setCurrentPage(page)}
						onChangeRowsPerPage={(newPerPage, page) => {
							setRowsPerPage(newPerPage);
							setCurrentPage(page);
						}}
						onSort={handleListSort}
            			sortServer
						progressPending={isLoading}
						customStyles={itLabTableStyle}
						persistTableHead
						dense
						highlightOnHover
						contextMessage={tableHelper.getContextMessage()}
						/>
					</div>
				</div>
			</MainLayout>
		</MasterLayout>
	);
}
